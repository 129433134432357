
import { Component, Vue } from 'vue-property-decorator';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import Layout from '@/admin/layouts/Default.vue';
import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/Table.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';

import SellersModule from '@/admin/store/sellers';
import UsersModule from '@/admin/store/users';

@Component({
    components: {
        FlexLayout,
        Layout,
        Loading,
        TableBlock,
        AppHeaderMain,
        TabsNav,
        TitleReturn,
    },
})
export default class AuctionHousesMain extends Vue {
    get settings() {
        return UsersModule.pageSettings;
    }

    get filterSettings() {
        return UsersModule.filter.filterSettings;
    }

    get isLoading(): boolean {
        return UsersModule.isLoading;
    }

    get paginationAction() {
        return UsersModule.updatePage;
    }

    get paginationItems(): { action: (value: string | number) => void; items: string } {
        return {
            action: UsersModule.updateItems,
            items: UsersModule.pageSettings.pageAmountItems,
        };
    }

    tableAction(params: any) {
        if (params.action === 'sort') {
            UsersModule.sort({ field: params.field, sort: params.sort });
        }
    }

    updateList() {
        UsersModule.changePage('1');
        UsersModule.getList();
    }

    async resetFilters() {
        await UsersModule.clearSort();
        await UsersModule.changePage('1');
        await UsersModule.filter.resetFilter();
        UsersModule.getList();
    }

    toAdd() {
        this.$router.push({ name: 'user_create' });
    }

    mounted() {
        UsersModule.init();
        SellersModule.initTabsItems();
    }

    beforeDestroy(): void {
        UsersModule.reset();
    }
}

import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';

import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

import { CityItem } from '@/interfaces/city.interface';
import { getUsersList } from '@/admin/api/users';
import { prepareTable } from '@/lib/Users';
import filterModel from './filter';
import UsersFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { FilterSearchParamsInterface } from '@/lib/layouts/page/filterParams.interface';

export const MODULE_NAME = 'users';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class UsersModule extends PageBaseModule {
    filter: Filter;
    cities: CityItem[] = [];

    constructor(module: UsersModule) {
        super(module);
        const page = new PageEntity();

        page.setTitle('Пользователи');
        page.setSortName('usersSort');
        page.setDefaultSortField('fullName');
        this.pageSettings = page.values;

        const filter = new filterModel();
        this.filter = getModule(UsersFilter);
        this.filter.setFilterName('usersFilter');
        this.filter.setBtnClassName('row-5');
        this.filter.setTemplateClassName('template-sm');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);
    }

    @Mutation
    SET_CITIES(list: CityItem[]) {
        this.cities = list;
    }

    @Mutation
    SET_PAGE(number: number) {
        this.pageSettings.pageCurrent = number;
    }

    @Action({ rawError: true })
    async init() {
        this.setDefaultSort();

        await this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        this.SET_IS_LOADING(true);

        const sort = await this.getSortForRequest();
        const result = await getUsersList(
            this.pageSettings.pageCurrent,
            sort,
            this.filter.filterSettings.filter,
            this.pageSettings.pageAmountItems,
        );

        await this.setList(result);
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setList(table: TableApiInterface) {
        const tableProcessed = await this.getProcessedTable(table);

        this.context.commit('SET_TABLE', tableProcessed);
    }

    @Action({ rawError: true })
    async getProcessedTable(table: TableApiInterface) {
        return await prepareTable(table);
    }

    @Action({ rawError: true })
    async updateSearch(params: FilterSearchParamsInterface) {
        await this.filter.updateSearch(params);
        this.filter.updateFilter();
    }
}

export default getModule(UsersModule);
